
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import UserService from "@/core/services/UserService";
import Swal from "sweetalert2";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "UpdatePassword",
  components: {FormErrorMessage, BaseForm, Field},
  setup() {
    const submitting = ref(false);
    const model = ref({
        oldPassword: '',
        password: '',
        rePassword: '',
      }
    )
    const validate = {}

    return {
      submitting,
      model,
      validate,
    }
  },
  methods: {
    onSave() {
      this.submitting = true
      UserService.changePassword(this.model).then(() => {
        JwtService.saveResetPassword(false);
        Swal.fire({
          title: 'Change Password Success',
          text: '',
          icon: 'success',
        })
        this.model = {
          oldPassword: '',
          password: '',
          rePassword: '',
        }
      }).finally(() => {
        this.submitting = false
      })
    }
  }
})
